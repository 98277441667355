<template>
	<div class="main">
		<!--顶部tap开始-->
		<div class="top-tap-box">
			<div class="top-tap-item" v-for="(item, index) in addData.contents" :key="index">
				<div  :class="index === tempIndex ? 'top-tap-item-select' : 'top-tap-item-def'">
					<div class="top-tap-text"  @click="changeTapIndex(index)">
						{{item.target?item.target:'请输入指标名称'}}
					</div>
				</div>
				<div class="span-line-icon"></div>
			</div>
		</div>
		<!--顶部tap结束-->
		<div class="add-item-box">
			<div class="item-box">
				<div class="item-title">
					<div class="item-star">*</div>
					<div>指标名称：</div>
				</div>
				<div class="item-input">
					<input v-model="addData.contents[tempIndex].target" placeholder="请输入" />
				</div>
			</div>
			<div class="item-box">
				<div class="item-title">
					<div class="item-star">*</div>
					<div>权重：</div>
				</div>
				<div class="item-input" @click="showSelectTarget">
					<span v-if="addData.contents[tempIndex].weight">{{addData.contents[tempIndex].weight}}%</span>
					<span v-else class="pla-color">请选择</span>
					<van-icon color="#999999" name="arrow" />
				</div>
			</div>
			<div class="item-box-field">
				<div class="item-title-file">
					<div class="item-star">*</div>
					<div>定义及计算方式：</div>
				</div>
			</div>
			<div class="file-box">
				<van-field
						style="font-size: 16px"
						v-model="addData.contents[tempIndex].computeMode"
						rows="3"
						autosize
						type="textarea"
						maxlength="150"
						placeholder="请输入定义及计算方式"
						show-word-limit
				/>
			</div>
			<div class="item-box">
				<div class="item-title">
					<div class="item-star">*</div>
					<div>目标值：</div>
				</div>
				<div class="item-input">
					<input v-model="addData.contents[tempIndex].targetValue" placeholder="请输入" />
				</div>
			</div>
			<div class="item-box">
				<div class="item-title">
					<div class="item-star">*</div>
					<div>数据来源：</div>
				</div>
				<div class="item-input">
					<input v-model="addData.contents[tempIndex].source" placeholder="请输入" />
				</div>
			</div>
			<div class="item-box-field">
				<div class="item-title">
					<div class="item-star"></div>
					<div>备注：</div>
				</div>
				<div class="item-input">
				</div>
			</div>
			<div class="file-box">
				<van-field
						style="font-size: 16px"
						v-model="addData.contents[tempIndex].remarks"
						rows="3"
						autosize
						type="textarea"
						maxlength="150"
						placeholder="请输入备注"
						show-word-limit
				/>
			</div>
		</div>
		<!--底部菜单开始-->
		<!--<div class="bottom-box">
			<van-grid :column-num="3">
				<van-grid-item @click="addItem">
					<span style="color: #5DA3FA">增加考核项</span>
				</van-grid-item>
				<van-grid-item @click="delItem">
					<span style="color: #f6404f">删除</span>
				</van-grid-item>
				<van-grid-item @click="saveData">
					<span style="color: #5DA3FA">保存</span>
				</van-grid-item>
			</van-grid>
		</div>-->
		<div class="bottom-box">
			<div @click="addItem" class="bottom-box-item bottom-box-item-add">增加考核项</div>
			<div @click="delItem" class="bottom-box-item bottom-box-item-del">删除</div>
			<div @click="saveData" class="bottom-box-item bottom-box-item-save">保存</div>
		</div>
		<!--底部菜单结束-->
		<!--选中权重开始-->
		<van-overlay :show="showOverlay" />
		<div v-show="showSelect" class="picker-box">
			<van-picker
					title="标题"
					show-toolbar
					:columns="qzOptions"
					@confirm="onConfirm"
					@cancel="onCancel"
			/>
		</div>
		<!--选择权重结束-->
	</div>
</template>

<script>
	import { editPerf } from '@/api/performance'
	import {Dialog, Toast} from 'vant';
	// import Cookies from "js-cookie";
	export default {
		name: 'addPref',
		data() {
			return {
				id: '',
				addData: {
					id: '',
					submitType: '',
					modifyType: '',
					images: '',
					months: '',
					contents: [
						{
							id: '',
							assessId: '',
							computeMode: '', // 定义及计算方式
							rater: '',
							raterName: '', //
							remarks: '', // 备注
							source: '', // 数据来源
							target: '', // 指标名称
							targetValue: '', // 目标值
							weight: '' // 权重
						}
					],
				},
				tempIndex: 0, // 当前选中的条目
				isMargin: '', // 判断是否添加顶部展示class
				qzOptions: [],
				showSelect: false, // 是否展示选择权重
				showOverlay: false, // 是否显示遮罩
				month: ''
			};
		},
		created() {
			const query = this.$route.query
			this.initQz()
			if (!query.month && !query.id) {
				Dialog.alert({title: '提示', message: '参数错误',})
				return
			}
			if(query.id){
				this.id = query.id
			}
			// 判断是否登录
			if(!sessionStorage.getItem("token")){
				//存储路由
				sessionStorage.setItem("perfPath", this.$route.path)
				sessionStorage.setItem("perfQuery", JSON.stringify(query))
				const postData = {}
				this.$router.push({ path: '/perfLogin', query: postData})
				return
			}
			this.month = query.month
			let tempData = sessionStorage.getItem('prefData')
			if(tempData){
				this.addData = JSON.parse(tempData)
				// 清除缓存
				sessionStorage.removeItem('prefData')
			}
			if (query.index) {
				this.tempIndex = query.index
			}
		},
		methods: {
			// 格式化权重配置
			initQz() {
				let i = 5
				while (i <= 100) {
					const tempData = i + '%'
					this.qzOptions.push(tempData)
					i = i + 5
				}
			},
			// 新增考核项
			addItem(){
				if(this.addData.contents.length >= 5){
					Toast('最多只能添加5项考核！')
					return
				}
				const tempItem = {
                            id: '',
							assessId: '',
							computeMode: '',
							rater: '',
							raterName: '',
							remarks: '',
							source: '',
							target: '',
							targetValue: '',
							weight: ''
				}
				this.addData.contents.push(tempItem)
				this.tempIndex = this.addData.contents.length - 1
			},
			// 删除考核项
			delItem(){
				if(this.addData.contents.length <= 1){
					Toast('请至少添加一项考核')
					return
				}
				this.addData.contents.splice(this.tempIndex, 1)
				this.tempIndex = 0
			},
			// 保存
			saveData(){
				if(this.id !== ''){
					this.addPerfData()
				}else{
					this.toSaveData()
				}
			},
			// 临时保存
			toSaveData(){
				if(this.addData.contents.length === 0){
					Toast('请至少添加一项考核')
					return
				}
				if(!this.authData()){
					return;
				}
				sessionStorage.setItem('prefData',JSON.stringify(this.addData))
				const postData = {}
				postData.month = this.month
				this.$router.push({ path: '/performance', query: postData})
			},
			// 验证提交数据
			authData(){
				let allWeight = 0
				for(let i = 0; i < this.addData.contents.length ; i++){
					let num = i + 1
					if(this.addData.contents[i].target === ''){
						Toast('请先填写第' + num + '项指标名称')
						return false
					}
					if(this.addData.contents[i].weight === ''){
						Toast('请先选择第' + num + '项权重')
						return false
					}
					if(this.addData.contents[i].computeMode === ''){
						Toast('请先填写第' + num + '项定义及计算方式')
						return false
					}
					if(this.addData.contents[i].targetValue === ''){
						Toast('请先填写第' + num + '项目标值')
						return false
					}
					if(this.addData.contents[i].source === ''){
						Toast('请先填写第' + num + '项数据来源')
						return false
					}
					allWeight = Number(allWeight) + Number(this.addData.contents[i].weight)
				}
				if(allWeight !== 100){
					Toast('权重之和必须等于100')
					return false
				}
				return true
			},
			// 提交数据
			addPerfData(){
				this.addData.submitType = 0
				if(this.addData.contents.length === 0){
					Toast('请至少添加一项考核')
					return
				}
				if(!this.authData()){
					return;
				}
				// 格式化数据
				const tempArr = []
				for(let i = 0; i < this.addData.contents.length; i++){
					let tempArrData = {}
					tempArrData.id = this.addData.contents[i].id
					tempArrData.rater = this.addData.contents[i].rater
					tempArrData.remarks = this.addData.contents[i].remarks
					tempArrData.source = this.addData.contents[i].source
					tempArrData.target = this.addData.contents[i].target
					tempArrData.targetValue = this.addData.contents[i].targetValue
					tempArrData.weight = this.addData.contents[i].weight
					tempArrData.computeMode = this.addData.contents[i].computeMode
					tempArr.push(tempArrData)
				}
				this.addData.contents = tempArr
				editPerf(this.addData).then(response => {
					console.log(response)
					if(response){
						Dialog.alert({title: '提示', message: '操作成功',})
					}
					sessionStorage.removeItem('prefData')
					const postData = {}
					postData.id = this.id
					this.$router.push({ path: '/leaderIndex', query: postData})
				})
			},
			// 顶部tap切换
			changeTapIndex(index){
				this.tempIndex = index
			},
			// 展示选择权重
			showSelectTarget(){
				this.showSelect = true
				this.showOverlay = true
			},
			// 确认选择权重
			onConfirm(value, index) {
				console.log(value)
				console.log(index)
				this.showSelect = false
				this.showOverlay = false
				this.addData.contents[this.tempIndex].weight = value.replace('%', '')
			},
			// 点击取消
			onCancel() {
				this.showSelect = false
				this.showOverlay = false
			},
		},
	};

</script>

<style scoped>
	input{
		border: none !important;
		text-align: right;
	}
	::-webkit-input-placeholder{color:#999999;}
	::-moz-placeholder{color:#999999;}
	:-moz-placeholder{color:#999999;}
	.add-item-box{
		background-color: #ffffff;
		margin-top: 50px;
	}
	.item-box{
		display: flex;
		justify-content: space-between;
		padding: 15px;
		color: #333333;
		font-size: 16px;
		border-bottom: 1px solid #F1F1F1;
	}
	.item-box-field{
		padding: 15px;
	}
	.item-title{
		display: flex;
		align-items: center;
		width: calc(30vw - 15px);
	}
	.item-title-file{
		display: flex;
		align-items: center;
		width: calc(100vw - 30px);
	}
	.item-star{
		padding-right: 6px;
		color: #F76161;
	}
	.item-input{
		width: calc(70vw - 15px);
		text-align: right;
		display: flex;
		justify-content: flex-end;
		align-items: center;
	}
	.pla-color{
		color: #999999;
		font-size: 16px;
	}
	.file-box{
		font-size: 16px;
		border-bottom: 1px solid #F1F1F1;
	}
	.bottom-box{
		position: fixed;
		bottom: 0;
		font-size: 15px;
		width: 100vw;
		display: flex;
		justify-content: space-between;
		align-items: center;
		text-align: center;
	}
	.bottom-box-item{
		width: 33vw;
		height: 49px;
		line-height: 49px;
	}
	.bottom-box-item-add{
		background-color: #ffffff;
		color: #5A7DF6;
	}
	.bottom-box-item-del{
		background-color: #F8F8F8;
		color: #FA7A6F;
	}
	.bottom-box-item-save{
		color: #ffffff;
		background: #5A7DF6;
	}
	.top-tap-box{
		position: fixed;
		top: 0;
		overflow: scroll;
		display: flex;
		justify-content: flex-start;
		white-space: nowrap;
		width: 100%;
		background-color: #ffffff;
		z-index: 99;
		border-bottom: 1px solid #F1F1F1;
	}
	.top-tap-item-def{
		height: 50px;
		line-height: 50px;
		font-size: 14px;
		margin: 0 10px;
		text-align: center;
	}
	.top-tap-item-select{
		height: 50px;
		line-height: 50px;
		font-size: 14px;
		border-bottom: 2px solid #5DA3FA;
		background-color: #ffffff;
		color: #5DA3FA;
		margin: 0 10px;
		text-align: center;
	}
	.top-tap-item{
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.span-line-icon{
		width: 1px;
		height: 50px;
		background: linear-gradient(180deg, rgba(255,255,255,0) 0%, #DDDDDD 53%, rgba(255,255,255,0) 100%);
		border-radius: 2px;
	}
	.picker-box{
		position: fixed;
		bottom: 0;
		width: 100vw;
		z-index: 100;
	}
	/deep/ .van-field__word-limit {
		color: #999999 !important;
	}
</style>
